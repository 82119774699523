
/*!
*  Author: WEBHOUSE, s. r. o.
*  Version: 1.0
*/


/*
* BASE DEPENDENCIES
*/

@import
	"ecco";


@import
    "shared/vars",
	"shared/animations"; 

/*
* COMMON BLOCKS
*/

@import 
    "common/templates",
    "common/_page",
    "common/_events",
    "common/_calendar",
    "common/_masonry",
    "common/_document";

/*
* DESIGN COMPONENTS
*/

@import
    "blocks/social",
    "blocks/tags",
    "blocks/tiles",
	"blocks/gallery",
	"blocks/pagination",
    "blocks/attachment",
    "blocks/blender";
